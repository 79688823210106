import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SocialLoginComponent } from '../../components/social-login/social-login.component';
import { MatDivider } from '@angular/material/divider';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { CustomButtonComponent } from '../../components/custom-button/custom-button.component';
import { Router } from '@angular/router';
import { AuthHeaderComponent } from '../../components/auth-header/auth-header.component';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { KeycloakAuth } from '../../services/keycloak/keycloak.service';
import { NgIf } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../services/user/user.service';
import { RegisterComponent } from '../register/register.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { SUPPORTED_LANGUAGES } from '../../../constants';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    SocialLoginComponent,
    MatDivider,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    CustomButtonComponent,
    AuthHeaderComponent,
    NgIf,
    RegisterComponent,
    ForgotPasswordComponent,
    MatError,
    TranslatePipe,
  ],
  // templateUrl: './login.component.html',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnChanges {
  @Input() language?: string = 'en';
  @Output() authChange = new EventEmitter<{
    access_token: string;
    refresh_token: string;
  }>();
  @Output() registerChange = new EventEmitter<{
    email: string;
    password: string;
  }>();
  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;
  loginUrl: string = '';
  showLogin: boolean = true;
  showRegister: boolean = false;
  showForgotPassword: boolean = false;
  errorMessage: string | null = null;
  isLoading: boolean = false;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(
    private router: Router,
    private readonly keycloakService: KeycloakService,
    private readonly authKeycloak: KeycloakAuth,
    private userService: UserService,
    protected sanitizer: DomSanitizer,
    private translate: TranslateService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['language']?.currentValue) {
      const language = changes['language']?.currentValue;

      const languageToUse = SUPPORTED_LANGUAGES.includes(language)
        ? language
        : 'en';
      this.translate.use(languageToUse);
    }
  }

  onSubmit() {
    const email = this.loginForm.value.email ?? '';
    const password = this.loginForm.value.password ?? '';
    this.isLoading = true;

    this.userService.loginUser(email, password).subscribe(
      (response) => {
        this.authChange.emit({
          access_token: response.access_token,
          refresh_token: response.refresh_token,
        });
        this.errorMessage = null;
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.errorMessage = error;
        this.isLoading = false;
      },
    );
  }

  onRegisterClick() {
    this.showRegister = true;
    this.showLogin = false;
    this.showForgotPassword = false;
  }

  onRegisterChange(event: { access_token: string; refresh_token: string }) {
    if (event.access_token && event.refresh_token) {
      this.authChange.emit({
        access_token: event.access_token,
        refresh_token: event.refresh_token,
      });
    }
  }

  onFlowChange(event: string) {
    this.showRegister = event === 'register';
    this.showLogin = event === 'login';
    this.showForgotPassword = event === 'forgot-password';
  }

  onForgotPasswordClick() {
    this.showForgotPassword = true;
    this.showLogin = false;
    this.showRegister = false;
  }
}
