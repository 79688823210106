<div [ngStyle]="{'opacity': disabled ? 0.5 : 1, 'cursor': disabled ? 'not-allowed' : 'pointer'}">
  <mat-select [disabled]="disabled" [(value)]="selectedRace" (selectionChange)="onRaceChange($event)" panelWidth="150px">
    <mat-select-trigger>
      {{ selectedRaceName | translate }}
    </mat-select-trigger>
    <mat-option *ngFor="let race of races" [value]="race.id">
      {{ race.name | translate }}
    </mat-option>
  </mat-select>
</div>
