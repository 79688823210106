<div class="container">
  <app-auth-header
    [title]="('auth.' + title) | translate"
    [subTitle]="('auth.' + subTitle)  | translate"
  ></app-auth-header>
  <div *ngIf="errorMessage">
    <mat-error style="text-align: center">
      {{ ('auth.' + errorMessage) | translate }}
    </mat-error>
  </div>
  <form *ngIf="!isEmailSent" [formGroup]="emailForm" (ngSubmit)="onSubmit()" style="margin-top: 40px">
    <div>
      <span class="input-label">{{ 'auth.Email Address' | translate }}</span>
      <mat-form-field>
        <input matInput id="email" formControlName="email" placeholder="example@neurun.com">
      </mat-form-field>
    </div>
    <button type="submit" style="display: none" #hiddenSubmitButton></button>
  </form>
</div>
<div style="margin-top: auto; padding: 16px">
  <app-custom-button type="submit" [title]="isEmailSent ? 'auth.Go Back to Login' : 'actions.Submit' | translate" variant="primary" [disabled]="!emailForm.valid || isLoading" (click)="onCustomButtonClick()"></app-custom-button>
</div>
