import { Component, EventEmitter, Output } from '@angular/core';
import { AuthHeaderComponent } from '../../components/auth-header/auth-header.component';
import { SocialLoginComponent } from '../../components/social-login/social-login.component';
import { MatDivider } from '@angular/material/divider';
import { CustomButtonComponent } from '../../components/custom-button/custom-button.component';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { MatList, MatListItem } from '@angular/material/list';
import { EMAIL_REGEX } from '../../../constants';
import { UserService } from '../../services/user/user.service';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    AuthHeaderComponent,
    SocialLoginComponent,
    MatDivider,
    CustomButtonComponent,
    FormsModule,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    NgIf,
    MatList,
    MatListItem,
    NgOptimizedImage,
    MatError,
    TranslatePipe,
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})
export class RegisterComponent {
  @Output() registerChange = new EventEmitter<{
    access_token: string;
    refresh_token: string;
  }>();
  @Output() flowChange = new EventEmitter<string>();
  currentStep: number = 1;
  registerForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(EMAIL_REGEX),
    ]),
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    password: new FormControl(''),
  });
  errorMessage: string | null = null;
  isLoading: boolean = false;

  constructor(private userService: UserService) {}

  onSubmit() {
    if (this.currentStep === 3) {
      const email = this.registerForm.value.email;
      const password = this.registerForm.value.password;
      const firstName = this.registerForm.value.first_name;
      const lastName = this.registerForm.value.last_name;

      if (email && password && firstName && lastName) {
        this.isLoading = true;
        this.userService
          .registerUser(email, password, firstName, lastName)
          .subscribe(
            (response) => {
              if (response) {
                this.registerChange.emit({
                  access_token: response.access_token,
                  refresh_token: response.refresh_token,
                });
              }
              this.isLoading = false;
              this.errorMessage = null;
            },
            (error) => {
              console.log(error);
              this.errorMessage = error;
              this.currentStep = 1;
              this.isLoading = false;
            },
          );
      }
      return;
    }

    this.currentStep += 1;
    this.updateValidators();
  }

  updateValidators() {
    const emailControl = this.registerForm.get('email');
    const firstNameControl = this.registerForm.get('first_name');
    const lastNameControl = this.registerForm.get('last_name');
    const passwordControl = this.registerForm.get('password');

    if (this.currentStep === 2) {
      emailControl?.clearValidators();
      firstNameControl?.setValidators([Validators.required]);
      lastNameControl?.setValidators([Validators.required]);
    }

    if (this.currentStep === 3) {
      emailControl?.clearValidators();
      lastNameControl?.clearValidators();
      lastNameControl?.clearValidators();
      passwordControl?.setValidators([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(32),
        this.passwordValidator,
      ]);
    }
  }

  passwordValidator(control: any) {
    const value = control.value;
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumeric = /[0-9]/.test(value);
    const hasSpecial = /[!@#\$%\^\&*\)\(+=._-]+/.test(value);

    const valid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecial;
    if (!valid) {
      return { passwordInvalid: true };
    }
    return null;
  }

  get password() {
    return this.registerForm.get('password');
  }

  get successMessage() {
    const email = this.registerForm.value.email;
    return `A confirmation email has been sent to <b>${email}</b> with all of your account details.`;
  }

  get passwordCriteria() {
    const value = this.password?.value || '';
    return {
      hasMinLength: value.length >= 8 && value.length <= 32,
      hasLowerCase: /[a-z]/.test(value),
      hasUpperCase: /[A-Z]/.test(value),
      hasNumeric: /[0-9]/.test(value),
      hasSpecial: /[!@#\$%\^\&*\)\(+=._-]+/.test(value),
    };
  }

  onLoginClick() {
    this.flowChange.emit('login');
  }
}
