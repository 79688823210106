<div>
  <span *ngIf="isMobile">
    <app-header
      [title]="race?.name || ''"
      [showAdditionalAction]="true"
      type="settings"
      (handleBackClick)="onBackClick()"
      (handleRightButtonClick)="onSettingsClick()"
    ></app-header>
  </span>

  <div class="chat-wrapper">
    <div #chatContainer
         class="chat-container"
         infinite-scroll
         [infiniteScrollDistance]=2
         [infiniteScrollUpDistance]=3
         [infiniteScrollThrottle]=300
         [scrollWindow]=false
         (scrolledUp)="onUp()"
    >
      <div *ngIf="isChatLoading" class="chat-loader-container">
        <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
      </div>

      <ng-container *ngIf="isChatHistoryEmpty">
        <div style="display: flex; justify-content: center; align-items: center; flex-direction: column">
          <div style="max-width: 400px">
            <p class="section-title">Your chat history is currently empty. Start by choosing your first request:</p>
            <div style="margin-top: 12px">
              <mat-chip-set>
                @for (request of filteredAiPrompts; track request; let  i = $index) {
                  <mat-chip class="neurun-ai-chip" color="primary" (click)="onAiRequestClick(i + 1)">{{ request }}</mat-chip>
                }
              </mat-chip-set>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngFor="let message of messages; let i = index">
        <div *ngIf="i === 0 || !isSameDay(messages[i-1].sent_at, message.sent_at)" class="date">
          {{ message.sent_at | date: 'MMMM d, yyyy' }}
        </div>
        <div *ngIf="message?.link_accounts_options && message?.link_accounts_options?.length; else defaultTemplate" class="fitness-wrapper">
          <mat-chip-set>
            @for (option of message?.link_accounts_options; track option) {
              <mat-chip class="fitness-container" (click)="onFitnessClick(option.name)">
                <img [src]="option.logo" alt="logo" style="min-height: 25px" />
              </mat-chip>
            }
          </mat-chip-set>
        </div>
        <div *ngIf="message?.table && message?.table!.length >= 1" style="margin: 16px 0">
          <div class="table-responsive">
            <div class="mat-elevation-z8">
              <table mat-table [dataSource]="newDataSource" class="mat-table">

                <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                  <th mat-header-cell *matHeaderCellDef
                      [ngStyle]="{
                        'min-width': column === 'Race Km' || column === 'Race Mile' ? '225px' :
                        column === 'Week' ? '150px' : '100px'
                   }">
                    {{ column }}
                  </th>
                  <td mat-cell *matCellDef="let element"> {{ element[column] }} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
        </div>
          <div *ngIf="message.is_elevation && message.gpx && message.widget">
              <div class="chat-elevation">
                  <app-elevation-profile
                          [coordinates]="message.gpx.tracks[0].geometry.coordinates"
                          [units]="race_units"
                          [showSelection]="false"
                  ></app-elevation-profile>
              </div>
          </div>
        <ng-template #defaultTemplate>
          <div class="chat-message" [ngClass]="{'ai-message': message.is_system, 'user-message': !message.is_system}">
            <div
              [ngClass]="{'message-content': !message?.is_options_message && !message?.widget && !message.is_map && (message?.text || message?.label), 'option-message': message?.is_options_message, 'map-message': message?.is_map}"
              style="overflow-y: auto"
            >
              <p *ngIf="message?.label && message.text" style="font-weight: 700">{{ message?.label }}</p>
              <div [innerHTML]="message.text"></div>
              <div *ngIf="message?.options?.length && message?.is_options_message" class="link-container">
                <mat-chip-set class="chip-container">
                  @for (option of message?.options; track option) {
                    <mat-chip class="option-container" (click)="onOptionClick(option)" [disabled]="option === 'Choose This Segment' && !segment_coordinates">
                      <span>{{ option }}</span>
                    </mat-chip>
                  }
                </mat-chip-set>
              </div>
              <div *ngIf="message?.gearOptions?.length" class="gear-options-container">
                @for (option of message?.gearOptions; track option) {
                  <div class="message-content" style="min-width: 180px; min-height: 100px; display: flex; flex-direction: column; justify-content: space-between; padding: 0">
                    <div *ngIf="option?.image">
                      <img [src]="option?.image" style="width: 180px; height: 135px; border-top-left-radius: 15px; border-top-right-radius: 15px; object-fit: contain" [alt]="option?.text" />
                    </div>
                    <div style="padding: 10px">
                      <p style="font-weight: 700">{{ option.text }}</p>
                      <div class="link-container" style="padding: 0 8px">
                          <a [href]='option?.link?.link' class="link" target="_blank" rel="noopener noreferrer">
                          {{ option?.link?.text }}
                        </a>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div *ngIf="message.is_map && path && segment_tracks">
                <div style="position: relative; overflow: hidden">
                  <app-routes-map [paths]="path"></app-routes-map>
                  <div style="position: absolute; bottom: 10px">
                    <app-route-swiper [routes]="segment_tracks" [units]="race_units"></app-route-swiper>
                  </div>
                </div>
              </div>
              <div *ngIf="message.is_map && message.gpx && message.widget">
                  <div style="position: relative; overflow: hidden">
                      <app-interactive-map [gpx]="message.gpx" [widget]="message.widget" [centerCoordinate]="message.center_coordinate"></app-interactive-map>
                  </div>
              </div>
              <div *ngIf="message.loading" class="three-dots-loader">
                <span></span><span></span><span></span>
              </div>
            </div>
          </div>
        </ng-template>
        <div *ngIf="message?.widget">
          <div *ngIf="message.widget === 'SEGMENT_PICKER' && elevationsData?.length">
            <app-elevation-profile [coordinates]="elevationsData" [showSelection]="true" (handleSegmentSelected)="onSegmentSelected($event)" [units]="race_units"></app-elevation-profile>
          </div>
          <div *ngIf="message.widget === 'RADIUS_PICKER' && radius_center_point" style="margin-bottom: 10px">
            <app-map-with-radius [startPoint]="radius_center_point || start_point" (handleSelectRadius)="onRadiusSelect($event)" [userUnit]="race_units"></app-map-with-radius>
          </div>
        </div>
      </ng-container>
      <div *ngIf="AIRequestError" style="margin-top: 16px">
        <p style="text-align: center; font-size: 13px; font-weight: 500">There was an error generating a response</p>
        <app-custom-button title="Regenerate Response" variant="secondary" (handleClick)="regenerateResponse()"></app-custom-button>
      </div>
      <div *ngIf="requestIndex && (requestIndex > 1 || requestIndex === -1) && messages?.length" style="margin: 32px 0">
        <div style="display: flex">
          <img ngSrc="assets/images/chat_bubble.svg" alt="AI Chat" height="24" width="24" style="margin-right: 8px"/>
          <span class="section-title">More Prompts:</span>
        </div>
        <div style="margin-top: 12px">
          <mat-chip-set>
            @for (request of filteredAiPrompts; track request; let  i = $index) {
              <mat-chip class="neurun-ai-chip" color="primary" (click)="onAiRequestClick(i + 1)" [disabled]="isMessageLoading">{{ request }}</mat-chip>
            }
          </mat-chip-set>
        </div>
      </div>

      <div *ngIf="chatError" class="error-message">
        {{ chatError }}
      </div>
    </div>
    <div *ngIf="showInput">
      <div class="input-container">
        <input
          class="chat-input"
          [(ngModel)]="userMessage"
          placeholder="Message Neurun AI"
          (keydown.enter)="sendMessage()"
        >
          <div class="btn-container">
              <div *ngIf="!userMessage.trim() && !isRecording">
                  <mat-menu
                          yPosition="above"
                          #menu="matMenu"
                  >
                      <ng-template matMenuContent>
                          <div class="dialog-content">
                              <label for="microphoneSelect">Select Microphone:</label>
                              <select
                                  id="microphoneSelect"
                                  [(ngModel)]="selectedDeviceId"
                                  (change)="selectDevice(selectedDeviceId)"
                                  (click) = "$event.stopPropagation()"
                              >
                                  <option *ngFor="let device of audioDevices" [value]="device.deviceId">
                                      {{ device.label || 'Unknown Microphone' }}
                                  </option>
                              </select>
                          </div>
                      </ng-template>
                  </mat-menu>
                  <button [matMenuTriggerFor]="menu" #menuTrigger class="mic-settings">
                      <mat-icon class="btn-icon">settings</mat-icon>
                  </button>
                  <button (click)="startMicRecording()" class="send-btn">
                      <mat-icon class="btn-icon">mic</mat-icon>
                  </button>
              </div>
              <button *ngIf="isRecording" (click)="stopMicRecording()" class="mic-btn-active">
                  <mat-icon class="btn-icon">mic</mat-icon>
              </button>
              <button *ngIf="userMessage.trim() && !isRecording" (click)="sendMessage()" class="send-btn"
                      [disabled]="!userMessage.trim()">
                  <mat-icon class="btn-icon">arrow_upward</mat-icon>
              </button>
          </div>
      </div>
    </div>
  </div>
</div>
