import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { RaceTypes } from '../../../types/models';
import { MatOption } from '@angular/material/autocomplete';
import {
  MatSelect,
  MatSelectChange,
  MatSelectTrigger,
} from '@angular/material/select';
import { NgForOf, NgStyle } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-race-selector',
  standalone: true,
  imports: [
    MatOption,
    MatSelect,
    NgForOf,
    TranslatePipe,
    MatSelectTrigger,
    NgStyle,
  ],
  templateUrl: './race-selector.component.html',
  styleUrl: './race-selector.component.scss',
})
export class RaceSelectorComponent implements OnInit, OnChanges {
  @Input() race: string | undefined = '';
  @Input() color: string = '';
  @Input() races: RaceTypes[] | [] = [];
  @Input() disabled?: boolean = false;
  @Output() raceChange = new EventEmitter<string>();
  selectedRace: string = '';
  selectedRaceName: string = '';

  ngOnInit() {
    this.setRace();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['color']?.currentValue) {
      const root = document.documentElement;
      root.style.setProperty('--main-color', this.color || '#6271FF');
    }
    if (changes['race']?.currentValue || changes['races']?.currentValue) {
      this.setRace();
    }
  }

  setRace() {
    if (this.race && this.races?.length > 0) {
      const raceFound = this.races.find((i) => i.id === this.race);
      if (raceFound) {
        this.selectedRace = raceFound.id;
        this.selectedRaceName = raceFound.name;
      }
    }
  }

  onRaceChange(event: MatSelectChange) {
    this.raceChange.emit(event.value);
    const raceFound = this.races.find((i) => i.id === this.race);
    if (raceFound) {
      this.selectedRaceName = raceFound.name;
    }
  }
}
