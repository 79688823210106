import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { provideNgxMask } from 'ngx-mask';
import { OidcServiceService } from '../../services/oidcService/oidc-service.service';
import { combineLatest } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-fitness-account-linking',
  standalone: true,
  imports: [MatProgressSpinner],
  templateUrl: './fitness-account-linking.component.html',
  styleUrl: './fitness-account-linking.component.scss',
  providers: [provideNgxMask()],
})
export class FitnessAccountLinkingComponent implements OnInit {
  provider: string | null = null;
  conversationGuid: string | null = null;
  constructor(
    private readonly oidcService: OidcServiceService,
    private router: ActivatedRoute,
  ) {}

  async ngOnInit() {
    combineLatest([this.router.paramMap, this.router.queryParamMap]).subscribe(
      ([paramMap]) => {
        this.provider = paramMap.get('provider');
        this.conversationGuid = paramMap.get('conversationGuid');

        this.manageOauthRedirect();
      },
    );
  }

  async manageOauthRedirect() {
    if (!(this.provider && this.conversationGuid)) {
      window.close();
      return;
    }

    localStorage.setItem('auth_provider', this.provider);
    const redirectUrl = `${window.location.origin}/auth-callback/${this.provider}/${this.conversationGuid}`;

    if (this.provider === 'garmin') {
      this.oidcService.getRequestToken(redirectUrl).subscribe((data) => {
        localStorage.setItem(
          'garmin_oauth_token_secret',
          data.oauthTokenSecret,
        );
        localStorage.setItem('garmin_oauth_token', data.oauthToken);
        window.location.href = data.authorizeUrl;
      });
    } else {
      const authUrl = (environment as any)[this.provider]?.auth
        ?.authorizationUrl;
      const clientId = (environment as any)[this.provider]?.auth?.client_id;
      const scope = (environment as any)[this.provider]?.auth?.scope;
      const authorizationUrl = `${authUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}${scope ? `&scope=${scope}` : ''}`;
      window.open(authorizationUrl, '_self', 'noopener');
    }
  }
}
