import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { AuthHeaderComponent } from '../../components/auth-header/auth-header.component';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { CustomButtonComponent } from '../../components/custom-button/custom-button.component';
import { NgIf } from '@angular/common';
import { UserService } from '../../services/user/user.service';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    AuthHeaderComponent,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    CustomButtonComponent,
    NgIf,
    TranslatePipe,
    MatError,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  @Output() flowChange = new EventEmitter<string>();
  @ViewChild('hiddenSubmitButton')
  hiddenSubmitButton!: ElementRef<HTMLButtonElement>;
  title: string = 'Forgot Password';
  subTitle: string =
    'Don’t worry, it happens. Enter the email associated with your account and we’ll send you a link to reset your password.';
  isEmailSent: boolean = false;
  isLoading: boolean = false;
  errorMessage: string | null = null;

  constructor(private userService: UserService) {}

  emailForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  onSubmit() {
    this.title = 'Email Sent';
    this.subTitle =
      'If there’s an account associated with the email you entered, we’ve sent a link to reset your password.';
    this.isEmailSent = true;
  }

  onCustomButtonClick() {
    if (this.isEmailSent) {
      this.flowChange.emit('login');
      return;
    }
    if (this.emailForm.valid) {
      this.isLoading = true;
      const email = this.emailForm.value.email as string;

      this.userService.resetPassword(email).subscribe(
        () => {
          this.isLoading = false;
          this.hiddenSubmitButton.nativeElement.click();
          this.errorMessage = null;
        },
        (error) => {
          console.log(error);
          this.errorMessage = error;
          this.isLoading = false;
        },
      );
    }
  }
}
