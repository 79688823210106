<div [ngStyle]="{'opacity': disabled ? 0.5 : 1, 'cursor': disabled ? 'not-allowed' : 'pointer'}">
  <mat-select [disabled]="disabled" [(value)]="selectedLanguage" (selectionChange)="onLanguageChange($event)" panelWidth="150px">
    <mat-select-trigger>
      {{ selectedLanguage | uppercase }}
    </mat-select-trigger>

    <mat-option *ngFor="let language of languages" [value]="language.code">
      {{ language.label }}
    </mat-option>
  </mat-select>
</div>
