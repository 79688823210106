import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SvgLogoService {
  constructor(private sanitizer: DomSanitizer) {}

  private base64EncodeUnicode(str: string): string {
    return btoa(unescape(encodeURIComponent(str)));
  }

  updateSvgLogo(
    template: string,
    color: string,
    colorToReplace: string = '',
  ): SafeUrl | string {
    if (color && template) {
      const svgWithColor = template.replaceAll(colorToReplace, color);
      const encodedSvg = this.base64EncodeUnicode(svgWithColor);
      const dataUrl = `data:image/svg+xml;base64,${encodedSvg}`;
      return this.sanitizer.bypassSecurityTrustUrl(dataUrl);
    } else {
      return template;
    }
  }
}
