import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';
import { from, Observable, of, Subject, switchMap, throwError } from 'rxjs';
import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { AthleteData } from '../../../types/models';

@Injectable({
  providedIn: 'root',
})
export class OidcServiceService {
  private redirectUrl: string = '';
  private readonly pAccessToken$ = new Subject<string | null>();
  readonly accessToken$ = this.pAccessToken$
    .asObservable()
    .pipe(distinctUntilChanged());

  private axiosInstance: AxiosInstance = axios.create();

  constructor() {
    this.pAccessToken$.next(this.accessToken);
  }

  get accessToken(): string | null {
    return window.localStorage.getItem('accessToken') || null;
  }

  get isLoggedIn(): boolean {
    return !!this.accessToken;
  }

  getRequestToken(
    redirectUrl: string,
    token?: string,
  ): Observable<{
    oauthToken: string;
    oauthTokenSecret: string;
    authorizeUrl: string;
  }> {
    // @ts-ignore
    const headers: AxiosRequestHeaders = token
      ? ({ Authorization: token } as AxiosRequestHeaders)
      : {};

    const tokenEndpoint = (environment as any)['garmin']?.auth?.tokenEndpoint;
    return from(
      this.axiosInstance.get(tokenEndpoint, {
        params: { callback_url: redirectUrl },
        headers,
      }),
    ).pipe(
      map((response) => response.data),
      catchError(this.handleError),
    );
  }

  getAccessToken(
    oauthToken: string,
    oauthTokenSecret: string,
    oauthVerifier: string,
    redirectUrl: string,
    token?: string,
  ): Observable<{ accessToken: string; accessTokenSecret: string }> {
    // @ts-ignore
    const headers: AxiosRequestHeaders = token
      ? ({ Authorization: token } as AxiosRequestHeaders)
      : {};

    const accessTokenEndpoint = (environment as any)['garmin']?.auth
      ?.accessTokenEndpoint;

    return from(
      this.axiosInstance.post(
        accessTokenEndpoint,
        { oauthToken, oauthTokenSecret, oauthVerifier },
        { params: { callback_url: redirectUrl }, headers },
      ),
    ).pipe(
      map((response) => response.data),
      catchError(this.handleError),
    );
  }

  openAuthWindow(
    provider: string,
    conversationGuid: string,
  ): Observable<string | null> {
    localStorage.setItem('auth_provider', provider);
    this.redirectUrl = `${window.location.origin}/auth-callback/${provider}/${conversationGuid}`;

    if (provider === 'garmin') {
      this.getRequestToken(this.redirectUrl).subscribe((data) => {
        localStorage.setItem(
          'garmin_oauth_token_secret',
          data.oauthTokenSecret,
        );
        localStorage.setItem('garmin_oauth_token', data.oauthToken);
        console.log('SET garmin_oauth_token_secret ==>', data.oauthTokenSecret);
        console.log('SET garmin_oauth_token ==>', data.oauthToken);
        window.open(data.authorizeUrl, '_blank', 'width=500, height=700');
      });
      return this.accessToken$;
    }

    const authUrl = (environment as any)[provider]?.auth?.authorizationUrl;
    const clientId = (environment as any)[provider]?.auth?.client_id;
    const scope = (environment as any)[provider]?.auth?.scope;
    const authorizationUrl = `${authUrl}?response_type=code&client_id=${clientId}&redirect_uri=${this.redirectUrl}${scope ? `&scope=${scope}` : ''}`;
    window.open(authorizationUrl, '_blank', 'noopener, width=500, height=700');

    const getAccessTokenCallback = (code: string) => {
      this.getNewAccessToken(code, provider, conversationGuid).subscribe(
        (data) => {
          if (data?.token) {
            this.setAccessToken(data.token);
          }
        },
      );
    };

    window.addEventListener('storage', function listener(event: StorageEvent) {
      if (event.storageArea !== localStorage) return;
      if (event.key === 'code') {
        const code = event.newValue;
        if (code) {
          getAccessTokenCallback(code);
        }
        window.removeEventListener('storage', listener);
      }
    });

    return this.accessToken$;
  }

  private setAccessToken(token: string | null): void {
    if (token) {
      window.localStorage.setItem('accessToken', token);
    } else {
      window.localStorage.removeItem('accessToken');
    }
    this.pAccessToken$.next(token);
  }

  public getNewAccessToken(
    code: string,
    provider: string,
    conversationGuid: string,
  ): Observable<{
    token: string;
    provider: string;
    refresh_token?: string;
    athlete_data?: AthleteData;
  } | null> {
    console.log('Getting new access token...');
    this.redirectUrl = `${window.location.origin}/auth-callback/${provider}/${conversationGuid}`;
    const { client_id, client_secret } = (environment as any)[provider].auth;
    const params = {
      grant_type: 'authorization_code',
      code: code,
      client_id: client_id,
      client_secret: client_secret,
      redirect_uri: this.redirectUrl,
    };

    return from(
      this.axiosInstance.post(
        (environment as any)[provider].auth.tokenEndpoint,
        null,
        { params },
      ),
    ).pipe(
      map((response) => response.data),
      map((data: any) => ({
        token: `${data?.token_type} ${data?.access_token}`,
        provider: provider,
        refresh_token: data?.refresh_token || '',
        athlete_data: data?.athlete,
      })),
      catchError(() => of(null)),
    );
  }

  public logout(token: string, provider: string): Observable<any> {
    const url = (environment as any)[provider].auth.deauthorizeUrl;
    const headers: AxiosRequestHeaders = {
      Authorization: token,
    } as AxiosRequestHeaders;
    return from(
      this.axiosInstance.post(url, undefined, {
        params: { access_token: token },
        headers,
      }),
    ).pipe(
      map((response) => response.data),
      catchError(this.handleError),
    );
  }

  refreshToken(refreshToken: string): Observable<{
    token: string;
    provider: string;
    refresh_token?: string;
  } | null> {
    const provider = localStorage.getItem('auth_provider') || '';
    const { client_id, client_secret } = (environment as any)[provider].auth;
    const params = {
      grant_type: 'refresh_token',
      client_id: client_id,
      refresh_token: refreshToken,
      client_secret: client_secret,
      redirect_uri: this.redirectUrl,
    };

    return from(
      this.axiosInstance.post(
        (environment as any)[provider].auth.tokenEndpoint,
        null,
        { params },
      ),
    ).pipe(
      map((response) => response.data),
      map((data: any) => ({ token: data.access_token, provider })),
      catchError(() => of(null)),
    );
  }

  public logoutStrava(
    providedToken: string,
    refreshToken: string,
  ): Observable<any> {
    return this.refreshToken(refreshToken).pipe(
      switchMap((response) => {
        if (response?.token) {
          const url = environment.strava.auth.deauthorizeUrl;
          const headers: AxiosRequestHeaders = {
            Authorization: `Bearer ${providedToken}`,
          } as AxiosRequestHeaders;
          return from(axios.post(url, {}, { headers })).pipe(
            map((res) => res.data),
          );
        } else {
          return throwError(() => new Error('Failed to refresh token'));
        }
      }),
      catchError(this.handleError),
    );
  }

  private handleError(error: any): Observable<never> {
    let errorMessage = 'An unknown error occurred!';
    if (error.isAxiosError) {
      if (error.response) {
        errorMessage = `Error Code: ${error.response.status}\nMessage: ${JSON.stringify(
          error.response.data,
        )}`;
      } else if (error.request) {
        errorMessage = 'No response received from server';
      } else {
        errorMessage = error.message;
      }
    } else {
      errorMessage = error.message ? error.message : error.toString();
    }
    return throwError(() => new Error(errorMessage));
  }
}
