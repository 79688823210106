<div>
  <div *ngIf="(races?.length && raceTypes?.length) || demo" class="wrapper" [ngStyle]="{'width': isMobile ? 'auto' : '100%'}">
    <div style="padding-bottom: 8px" [ngStyle]="{'width': isMobile ? '100%' : '600px'}">
      <div style="display: flex; align-items: center; justify-content: space-between">
        <div style="margin-left: 12px">
          <img [src]="raceConciergeLogo" alt="Race Concierge" />
        </div>
        <div style="display: flex; align-items: center; justify-content: right; gap: 12px; margin: 0 16px">
          <app-race-selector [race]="selectedRace" [races]="raceTypes" [color]="promptColor" (raceChange)="handleRaceChange($event)"></app-race-selector>
          <app-language-selector [language]="currentLanguage" [color]="promptColor || '#6271FF'" (languageChange)="handleLanguageChange($event)"></app-language-selector>
        </div>
      </div>
    </div>
    <div class="container" [ngStyle]="{'width': isMobile ? '100%' : '600px', 'background': widgetConfiguration?.style?.main_color ? widgetConfiguration?.style?.main_color : 'linear-gradient(to right, #4954B8, #606FF8)'}">
      <div class="search-input-wrapper">
          <span class="search-icon" (click)="sendMessage()">
            <img
              [src]="searchLogo"
              alt="Search Icon"
            />
          </span>
        <input
          [(ngModel)]="userMessage"
          placeholder="{{'Ask a race or course question' | translate}}"
          class="search-input"
          (keydown.enter)="sendMessage()"
        >
          <span *ngIf="userMessage?.length; else micButton" style="padding: 0; margin: 0; display: flex">
            <button (click)="sendMessage()" class="send-btn" style="margin-bottom: 4px">
              <img [src]="arrowForwardLogo" alt="Arrow forward">
            </button>
          </span>
          <ng-template #micButton>
            <button (click)="openVoiceInput()" class="send-btn">
              <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzkwNV81MTgiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjAiIHk9IjAiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+CjxyZWN0IHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iI0Q5RDlEOSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfOTA1XzUxOCkiPgo8cGF0aCBkPSJNMTIgMTMuNUMxMS4zMDEzIDEzLjUgMTAuNzEgMTMuMjU4IDEwLjIyNiAxMi43NzRDOS43NDIgMTIuMjkgOS41IDExLjY5ODcgOS41IDExVjVDOS41IDQuMzAxMzMgOS43NDIgMy43MSAxMC4yMjYgMy4yMjZDMTAuNzEgMi43NDIgMTEuMzAxMyAyLjUgMTIgMi41QzEyLjY5ODcgMi41IDEzLjI5IDIuNzQyIDEzLjc3NCAzLjIyNkMxNC4yNTggMy43MSAxNC41IDQuMzAxMzMgMTQuNSA1VjExQzE0LjUgMTEuNjk4NyAxNC4yNTggMTIuMjkgMTMuNzc0IDEyLjc3NEMxMy4yOSAxMy4yNTggMTIuNjk4NyAxMy41IDEyIDEzLjVaTTExLjI1IDIwLjc1VjE3LjQ1MzhDOS42IDE3LjI2NTMgOC4yMjkxNyAxNi41NTc2IDcuMTM3NSAxNS4zMzA3QzYuMDQ1ODMgMTQuMTAzNyA1LjUgMTIuNjYwMiA1LjUgMTFIN0M3IDEyLjM4MzMgNy40ODc1IDEzLjU2MjUgOC40NjI1IDE0LjUzNzVDOS40Mzc1IDE1LjUxMjUgMTAuNjE2NyAxNiAxMiAxNkMxMy4zODMzIDE2IDE0LjU2MjUgMTUuNTEyNSAxNS41Mzc1IDE0LjUzNzVDMTYuNTEyNSAxMy41NjI1IDE3IDEyLjM4MzMgMTcgMTFIMTguNUMxOC41IDEyLjY2MDIgMTcuOTU0MiAxNC4xMDM3IDE2Ljg2MjUgMTUuMzMwN0MxNS43NzA4IDE2LjU1NzYgMTQuNCAxNy4yNjUzIDEyLjc1IDE3LjQ1MzhWMjAuNzVIMTEuMjVaIiBmaWxsPSIjN0U3RTdFIi8+CjwvZz4KPC9zdmc+Cg==" alt="Mic">
            </button>
          </ng-template>
          <p class="error" *ngIf="error">{{error}}</p>
      </div>
      <app-prompts-list
        [disabled]="false"
        [prompts]="prompts"
        (handlePromptClick)="selectDefaultPrompt($event)"
        [isMobile]="isMobile"
        [mainColor]="widgetConfiguration?.style?.main_color || '#6271FF'"
      ></app-prompts-list>
    </div>
  </div>
  <!--  <ng-container *ngIf="showLoginIframe">-->
  <!--    <app-auth-drawer [isOpen]="isDrawerOpen" [keycloakLoginUrl]="keycloakLoginUrl" (handleChangeDrawerState)="handleChangeDrawerState($event)"></app-auth-drawer>-->
  <!--  </ng-container>-->
  <ng-container *ngIf="isAIDrawerOpen">
    <app-ai-chat-wrapper
      [isOpen]="isAIDrawerOpen"
      (handleChangeDrawerState)="handleChangeDrawerState($event)"
      (handleChangeLanguage)="handleLanguageChange($event)"
      [prompt]="selectedPrompt"
      [raceId]="selectedRace"
      [races]="races"
      [raceTypes]="raceTypes"
      [isVoiceMode]="isVoiceMode"
      [mainColor]="widgetConfiguration?.style?.main_color || '#6271FF'"
      [language]="currentLanguage"
    >
    </app-ai-chat-wrapper>
  </ng-container>
</div>
