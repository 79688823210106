<div class="wrapper">
  <div class="header">
    <div>
      <div style="display: flex; align-items: center; gap: 12px">
        <app-race-selector [race]="currentRace?.guid" [races]="raceTypes" [color]="mainColor" (raceChange)="handleRaceChange($event)" [disabled]="isChatLoading || isChatHistoryLoading"></app-race-selector>
        <app-language-selector [language]="language" [color]="mainColor || '#6271FF'" (languageChange)="handleLanguageChange($event)" [disabled]="isChatLoading || isChatHistoryLoading"></app-language-selector>
      </div>
    </div>
    <span style="cursor: pointer" (click)="closeDrawer()">
        <img
          [src]="closeLogo"
          alt="Close"
          style="cursor: pointer"
        />
    </span>
  </div>
    <div *ngIf="voiceMode && access_token" class="voice-mode-container">
      <div class="voice-mode-content">
        <button class="voice-mode-start-button" [disabled]="isRecording" (click)="startMicRecording()">
          <img ngSrc="/assets/images/mic-icon-white.svg" alt="progress" height="64" width="64">
        </button>
        <span class="{{isRecording ? 'span-hidden' : '' }}">{{ 'Press to start' | translate }}</span>
        <p class="voice-mode-text">{{ 'Ask race event' | translate }}<br>{{ 'or course questions...' | translate }}</p>
        <div *ngIf="isRecording" class="voice-mode-loader">
          <img ngSrc="/assets/images/voice_input.gif" alt="Recording..." height="32" width="368">
        </div>
        <p class="voice-mode-input">{{userMessage}}</p>
      </div>
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 12px">
        <button class="exit-voice-mode-button" (click)="cancelVoiceMode()">{{ 'actions.Cancel' | translate }}</button>
      </div>
    </div>
  <div class="chat-wrapper">
    <div
      #chatContainer
      class="chat-container"
      [ngStyle]="{'justify-content': !access_token ? 'center' : 'flex-start' }"
      infinite-scroll
      [infiniteScrollDistance]=2
      [infiniteScrollUpDistance]=3
      [infiniteScrollThrottle]=300
      [scrollWindow]=false
      (scrolledUp)="onUp()"
    >
      <div *ngIf="isChatLoading || isChatHistoryLoading" class="chat-loader-container" [ngStyle]="{'margin-top': isChatHistoryLoading ? '0px' : '50%'}">
        <div class="custom-loader"></div>
      </div>

      <div *ngIf="!access_token && !isChatLoading; else chatContent">
        <app-login (authChange)="onAuthChange($event)" [language]="language" />
      </div>

      <ng-template #chatContent>
        <ng-container *ngFor="let message of messages; let i = index">
          <div *ngIf="i === 0 || !isSameDay(messages[i-1].sent_at, message.sent_at)" class="date">
            {{ message.sent_at | date:'MMMM d, yyyy':undefined:language }}
          </div>
          <div *ngIf="(message?.link_accounts_options && message?.link_accounts_options?.length) || (message?.partner_bot_options && message?.partner_bot_options?.length); else defaultTemplate" class="fitness-wrapper">
            <div *ngIf="message?.link_accounts_options && message?.link_accounts_options?.length">
              <mat-chip-set>
                @for (option of message?.link_accounts_options; track option) {
                  <mat-chip
                    class="fitness-container"
                    (click)="onFitnessClick(option.name)"
                    [disabled]="option.name === 'coros' || option.name === 'garmin'"
                    [ngStyle]="{ 'background': option.name === 'garmin' || option.name === 'coros' && '#ccc', cursor: option.name === 'garmin' || option.name === 'coros' && 'not-allowed', opacity: option.name === 'garmin' || option.name === 'coros' && '0.5' }"
                  >
                    <img [src]="option.logo" alt="logo" style="min-height: 25px" />
                    <div *ngIf="option.name === 'coros' || option.name === 'garmin'" style="text-align: center">
                      (Available soon)
                    </div>
                  </mat-chip>
                }
              </mat-chip-set>
            </div>
            <div *ngIf="message?.partner_bot_options && message?.partner_bot_options?.length">
              <mat-chip-set>
                @for (option of message?.partner_bot_options; track option) {
                  <mat-chip
                    class="fitness-container"
                    (click)="onPartnerBotClick(option.name)"
                  >
                    <img [src]="option.logo" alt="logo" style="min-height: 25px; max-height: 80%; max-width: 85%;" />
                  </mat-chip>
                }
              </mat-chip-set>
            </div>
            {{message?.partner_site}}
          </div>
          <div >
            <mat-chip-set>
              @for (option of message?.partner_site; track option) {
                <a [href]="option?.url" class="link" target="_blank" rel="noopener noreferrer">
                  <div>
                    <img [src]="option.logo" alt="logo" style="min-height: 25px; max-height: 80%; max-width: 85%;" />
                  </div>
                </a>
              }
            </mat-chip-set>
          </div>

          <div *ngIf="message?.table && message?.table!.length >= 1" style="margin: 16px 0">
            <div class="table-responsive">
              <div class="mat-elevation-z8">
                <table mat-table [dataSource]="newDataSource" class="mat-table">

                  <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                    <th mat-header-cell *matHeaderCellDef
                        [ngStyle]="{
                        'min-width': column === 'Race Km' || column === 'Race Mile' ? '225px' :
                        column === 'Week' ? '150px' : '100px'
                   }">
                      {{ column | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{ element[column] }} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
            </div>
          </div>
          <div *ngIf="message.is_elevation && message.gpx && message.widget">
            <div class="chat-elevation">
              <app-elevation-profile
                [coordinates]="message.gpx.tracks[0].geometry.coordinates"
                [units]="race_units"
                [showSelection]="false"
              ></app-elevation-profile>
            </div>
          </div>
          <ng-template #defaultTemplate>
            <div class="chat-message" [ngClass]="{'ai-message': message.is_system, 'user-message': !message.is_system}">
              <div
                [ngClass]="{'message-content': !message?.is_options_message && !message?.widget && !message.is_map && (message?.text || message?.label), 'option-message': message?.is_options_message, 'map-message': message?.is_map}"
                style="overflow-y: auto"
              >
                <p *ngIf="message?.label && message.text" style="font-weight: 700">{{ message?.label }}</p>
                <div [innerHTML]="message.text"></div>
                <div *ngIf="message?.options?.length && message?.is_options_message" class="link-container">
                  <mat-chip-set class="chip-container">
                    @for (option of message?.options; track option) {
                      <mat-chip class="option-container" (click)="onOptionClick(option)" [disabled]="option === 'Choose This Segment' && !segment_coordinates">
                        <span>{{ option | translate }}</span>
                      </mat-chip>
                    }
                  </mat-chip-set>
                </div>
                <div *ngIf="message?.gearOptions?.length" class="gear-options-container">
                  @for (option of message?.gearOptions; track option) {
                    <div class="message-content" style="min-width: 180px; min-height: 100px; display: flex; flex-direction: column; justify-content: space-between; padding: 0">
                      <div *ngIf="option?.image">
                        <img [src]="option?.image" style="width: 180px; height: 185px; border-top-left-radius: 15px; border-top-right-radius: 15px; object-fit: cover" [alt]="option?.text" />
                      </div>
                      <div style="padding: 10px">
                        <p style="font-weight: 700; margin: 2px 0; font-size: 16px">{{ option.text }}</p>
                        <div class="link-container">
                            <a [href]='option?.link?.link' class="link" target="_blank" rel="noopener noreferrer">
                            {{ option?.link?.text }}
                            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzY3M18xMjA1IiBzdHlsZT0ibWFzay10eXBlOmFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjUiIGhlaWdodD0iMjUiPgo8cmVjdCB4PSIwLjI2MjY5NSIgeT0iMC4zNTU5NTciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iI0Q5RDlEOSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfNjczXzEyMDUpIj4KPHBhdGggZD0iTTE2Ljg4OTcgMTMuMTA2SDQuNzYyN1YxMS42MDZIMTYuODg5N0wxMS4xOTM0IDUuOTA5NzFMMTIuMjYyNyA0Ljg1NTk2TDE5Ljc2MjcgMTIuMzU2TDEyLjI2MjcgMTkuODU2TDExLjE5MzQgMTguODAyMkwxNi44ODk3IDEzLjEwNloiIGZpbGw9IiM2MjcxRkYiLz4KPC9nPgo8L3N2Zz4K" alt="Arrow forward" />
                          </a>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div *ngIf="message.is_map && path && segment_tracks && !message.gpx">
                  <div style="position: relative; overflow: hidden">
                    <app-routes-map [paths]="path"></app-routes-map>
                    <div style="position: absolute; bottom: 10px">
                      <app-route-swiper [routes]="segment_tracks" [units]="race_units"></app-route-swiper>
                    </div>
                  </div>
                </div>
                <div *ngIf="message.is_map && message.gpx && message.widget">
                  <div style="position: relative; overflow: hidden">
                    <app-interactive-map [gpx]="message.gpx" [widget]="message.widget" [centerCoordinate]="message.center_coordinate"></app-interactive-map>
                  </div>
                </div>
                <div *ngIf="message.loading" class="three-dots-loader">
                  <span></span><span></span><span></span>
                </div>
              </div>
            </div>
          </ng-template>
          <div *ngIf="message?.widget">
            <div *ngIf="message.widget === 'SEGMENT_PICKER' && elevationsData?.length">
              <app-elevation-profile [coordinates]="elevationsData" [showSelection]="true" (handleSegmentSelected)="onSegmentSelected($event)" [units]="race_units"></app-elevation-profile>
            </div>
            <div *ngIf="message.widget === 'RADIUS_PICKER' && radius_center_point" style="margin-bottom: 10px">
              <app-map-with-radius [startPoint]="radius_center_point || start_point" (handleSelectRadius)="onRadiusSelect($event)" [userUnit]="race_units" [mainColor]="mainColor"></app-map-with-radius>
            </div>
          </div>
        </ng-container>
        <div *ngIf="AIRequestError" style="margin-top: 16px">
          <p style="text-align: center; font-size: 13px; font-weight: 500">{{ 'There was an error generating a response' | translate }}</p>
          <app-custom-button [title]="'Regenerate Response' | translate" variant="secondary" (handleClick)="regenerateResponse()"></app-custom-button>
        </div>
      </ng-template>
      <div *ngIf="showLoadMore && !isChatHistoryLoading && !isChatLoading && ((messages?.length ?? 0) > 20)" class="load-more-btn-container">
        <span class="load-more-btn" (click)="onUp()">
          {{ 'Load more' | translate }}
        </span>
      </div>
      <div class="move-down-btn" *ngIf="!isAtBottom && !isChatHistoryLoading" (click)="scrollToBottom()">
        <img
          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBVcGxvYWRlZCB0bzogU1ZHIFJlcG8sIHd3dy5zdmdyZXBvLmNvbSwgR2VuZXJhdG9yOiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIGZpbGw9IiMwMDAwMDAiIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgDQoJIHdpZHRoPSI4MDBweCIgaGVpZ2h0PSI4MDBweCIgdmlld0JveD0iMCAwIDMwLjAyMSAzMC4wMjEiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTI4LjYxMSwxMy4zODVsLTExLjAxMSw5LjM1MmMtMC43NDUsMC42MzMtMS42NjcsMC45NDktMi41ODksMC45NDljLTAuOTIxLDAtMS44NDItMC4zMTYtMi41ODktMC45NDlMMS40MTEsMTMuMzg1DQoJCWMtMS42ODQtMS40My0xLjg5LTMuOTU0LTAuNDYtNS42MzhjMS40MzEtMS42ODQsMy45NTUtMS44OSw1LjYzOS0wLjQ1OWw4LjQyMSw3LjE1MWw4LjQyLTcuMTUxDQoJCWMxLjY4Ni0xLjQzLDQuMjA5LTEuMjI0LDUuNjM5LDAuNDU5QzMwLjUsOS40MzEsMzAuMjk0LDExLjk1NSwyOC42MTEsMTMuMzg1eiIvPg0KPC9nPg0KPC9zdmc+"
          alt="Down button"
          style="width: 30px; height: 30px"
        />
      </div>
    </div>
    <div class="input-container" *ngIf="access_token">
      <input
        class="chat-input"
        [disabled]="!access_token"
        [(ngModel)]="userMessage"
        [placeholder]="'Ask a follow up question' | translate"
        (keydown.enter)="sendMessage()"
      >
        <div class="btn-container">
            <div *ngIf="!userMessage.trim() && !isRecording" style="display: flex; align-items: center">
                <mat-menu
                        yPosition="above"
                        #menu="matMenu"
                >
                    <ng-template matMenuContent>
                        <div class="dialog-content">
                            <label for="microphoneSelect">{{ 'Select Microphone:' | translate }}</label>
                            <select
                                    id="microphoneSelect"
                                    [(ngModel)]="selectedDeviceId"
                                    (change)="selectDevice(selectedDeviceId)"
                                    (click) = "$event.stopPropagation()"
                            >
                                <option *ngFor="let device of audioDevices" [value]="device.deviceId">
                                    {{ device.label || 'Unknown Microphone' }}
                                </option>
                            </select>
                        </div>
                    </ng-template>
                </mat-menu>
                <button [matMenuTriggerFor]="menu" #menuTrigger class="mic-settings">
                    <mat-icon class="btn-icon">settings</mat-icon>
                </button>
                <button (click)="startMicRecording()" [disabled]="!access_token" class="send-btn" style="background: none">
                  <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzkwNV81MTgiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjAiIHk9IjAiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+CjxyZWN0IHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iI0Q5RDlEOSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfOTA1XzUxOCkiPgo8cGF0aCBkPSJNMTIgMTMuNUMxMS4zMDEzIDEzLjUgMTAuNzEgMTMuMjU4IDEwLjIyNiAxMi43NzRDOS43NDIgMTIuMjkgOS41IDExLjY5ODcgOS41IDExVjVDOS41IDQuMzAxMzMgOS43NDIgMy43MSAxMC4yMjYgMy4yMjZDMTAuNzEgMi43NDIgMTEuMzAxMyAyLjUgMTIgMi41QzEyLjY5ODcgMi41IDEzLjI5IDIuNzQyIDEzLjc3NCAzLjIyNkMxNC4yNTggMy43MSAxNC41IDQuMzAxMzMgMTQuNSA1VjExQzE0LjUgMTEuNjk4NyAxNC4yNTggMTIuMjkgMTMuNzc0IDEyLjc3NEMxMy4yOSAxMy4yNTggMTIuNjk4NyAxMy41IDEyIDEzLjVaTTExLjI1IDIwLjc1VjE3LjQ1MzhDOS42IDE3LjI2NTMgOC4yMjkxNyAxNi41NTc2IDcuMTM3NSAxNS4zMzA3QzYuMDQ1ODMgMTQuMTAzNyA1LjUgMTIuNjYwMiA1LjUgMTFIN0M3IDEyLjM4MzMgNy40ODc1IDEzLjU2MjUgOC40NjI1IDE0LjUzNzVDOS40Mzc1IDE1LjUxMjUgMTAuNjE2NyAxNiAxMiAxNkMxMy4zODMzIDE2IDE0LjU2MjUgMTUuNTEyNSAxNS41Mzc1IDE0LjUzNzVDMTYuNTEyNSAxMy41NjI1IDE3IDEyLjM4MzMgMTcgMTFIMTguNUMxOC41IDEyLjY2MDIgMTcuOTU0MiAxNC4xMDM3IDE2Ljg2MjUgMTUuMzMwN0MxNS43NzA4IDE2LjU1NzYgMTQuNCAxNy4yNjUzIDEyLjc1IDE3LjQ1MzhWMjAuNzVIMTEuMjVaIiBmaWxsPSIjN0U3RTdFIi8+CjwvZz4KPC9zdmc+Cg==" alt="Mic">
                </button>
            </div>
            <button *ngIf="isRecording" (click)="stopMicRecording()" class="mic-btn-active">
                <mat-icon class="btn-icon">mic</mat-icon>
            </button>
            <button *ngIf="userMessage.trim() && !isRecording" (click)="sendMessage()" class="send-btn"
                    [disabled]="!userMessage.trim()" [ngStyle]="{ 'background': mainColor || '#6271FF' }">
                <mat-icon class="btn-icon">arrow_upward</mat-icon>
            </button>
        </div>
    </div>
    <div class="additional-prompts" [ngStyle]="{ 'background': mainColor || 'linear-gradient(to bottom, #4C57BD, #6272FE)' }" style="width: 100%">
      <app-prompts-list [disabled]="!access_token" [prompts]="prompts" (handlePromptClick)="onPromptClick($event)" [prefix]="'auth.Additional prompts:' | translate" [mainColor]="mainColor"></app-prompts-list>
    </div>
  </div>
</div>
