<div>
    <div class="chat-loader-container">
        <mat-progress-spinner *ngIf="!isFailure" mode="indeterminate" diameter="50"></mat-progress-spinner>
        <div class="error-container" *ngIf="isFailure">
            <h1>Failed to link your fitness account</h1>
            <h1>Please try again later or select another fitness provider</h1>
            <p>The page will be closed in</p>
            <app-countdown-timer [duration]="10" (countdownFinished)="closeWindow()"></app-countdown-timer>
        </div>
    </div>
</div>
