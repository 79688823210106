import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
  standalone: true,
  imports: [MatProgressBar, MatCardContent, MatCardTitle, MatCard],
})
export class CountdownTimerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() duration: number = 10;
  @Output() countdownFinished = new EventEmitter<void>();

  timeLeft: number = 0;
  private subscription!: Subscription;

  ngOnInit() {
    this.startCountdown();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['duration']) {
      this.startCountdown();
    }
  }

  startCountdown() {
    this.subscription?.unsubscribe();
    this.timeLeft = this.duration;
    this.subscription = interval(1000)
      .pipe(take(this.duration))
      .subscribe({
        next: () => this.timeLeft--,
        complete: () => this.countdownFinished.emit(),
      });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
