import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatOption } from '@angular/material/autocomplete';
import {
  MatSelect,
  MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';
import { NgForOf, NgStyle, UpperCasePipe } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';

export interface LanguageOption {
  code: string;
  label: string;
}

@Component({
  selector: 'app-language-selector',
  standalone: true,
  imports: [
    MatOption,
    MatSelect,
    NgForOf,
    UpperCasePipe,
    MatSelectModule,
    MatOptionModule,
    NgStyle,
  ],
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss',
})
export class LanguageSelectorComponent implements OnInit, OnChanges {
  @Input() language: string = '';
  @Input() color: string = '';
  @Input() disabled?: boolean = false;
  @Output() languageChange = new EventEmitter<string>();
  languages: LanguageOption[] = [
    { code: 'EN', label: 'English' },
    { code: 'FR', label: 'French' },
    { code: 'DE', label: 'German' },
    { code: 'ES', label: 'Spanish' },
    { code: 'JA', label: 'Japanese' },
  ];
  selectedLanguage: string = 'EN';

  ngOnInit() {
    this.setLanguage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mainColor']?.currentValue) {
      const root = document.documentElement;
      root.style.setProperty('--main-color', this.color || '#6271FF');
    }

    if (changes['language']?.currentValue) {
      this.setLanguage();
    }
  }

  setLanguage() {
    const langFound = this.languages.find(
      (lang) => lang.code === this.language.toUpperCase(),
    );
    if (langFound) {
      this.selectedLanguage = langFound.code;
    }
  }

  onLanguageChange(event: MatSelectChange) {
    this.languageChange.emit(event.value);
  }
}
