import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { provideNgxMask } from 'ngx-mask';
import { OidcServiceService } from '../../services/oidcService/oidc-service.service';
import { combineLatest } from 'rxjs';
import { UserService } from '../../services/user/user.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
import { CountdownTimerComponent } from '../../components/countdown-timer/countdown-timer.component';

@Component({
  selector: 'app-auth-callback',
  standalone: true,
  imports: [MatProgressSpinner, NgIf, CountdownTimerComponent],
  templateUrl: './auth-callback.component.html',
  styleUrl: './auth-callback.component.scss',
  providers: [provideNgxMask()],
})
export class AuthCallbackComponent implements OnInit {
  provider: string | null = null;
  code: string | null = null;
  oauth_token: string | null = null;
  oauth_verifier: string | null = null;
  conversationGuid: string | null = null;
  isFailure: boolean = false;
  private boundOnError: () => void;
  constructor(
    private readonly oidcService: OidcServiceService,
    private router: ActivatedRoute,
    private userService: UserService,
  ) {
    this.boundOnError = this.onError.bind(this);
  }

  async ngOnInit() {
    combineLatest([this.router.paramMap, this.router.queryParamMap]).subscribe(
      ([paramMap, queryParamMap]) => {
        this.provider = paramMap.get('provider');
        this.conversationGuid = paramMap.get('conversationGuid');
        this.code = queryParamMap.get('code');
        this.oauth_token = queryParamMap.get('oauth_token');
        this.oauth_verifier = queryParamMap.get('oauth_verifier');

        this.getToken();
      },
    );
  }

  async onError() {
    console.log('OnError');
    this.isFailure = true;
  }

  async closeWindow() {
    setTimeout(() => {
      window.close();
    }, 100);
  }

  async sendToken(data: {
    token_secret?: string;
    token: string;
    account_type: string;
    conversation_guid: string;
  }) {
    this.userService
      .sendActivityTokenWithAxios(data)
      .subscribe(() => console.log('activity data sent'));
  }

  async getToken() {
    if (this.provider === 'garmin') {
      const garminOAuthTokenSecret = localStorage.getItem(
        'garmin_oauth_token_secret',
      );
      const garminOAuthToken = localStorage.getItem('garmin_oauth_token');
      if (garminOAuthTokenSecret && garminOAuthToken && this.oauth_verifier) {
        const redirectUrl = `${window.location.origin}/auth-callback/${this.provider}/${this.conversationGuid}`;
        this.oidcService
          .getAccessToken(
            garminOAuthToken,
            garminOAuthTokenSecret,
            this.oauth_verifier,
            redirectUrl,
          )
          .subscribe((data) => {
            if (
              data?.accessToken &&
              data?.accessTokenSecret &&
              this.provider &&
              this.conversationGuid
            ) {
              this.sendToken({
                account_type: this.provider,
                token: data.accessToken,
                token_secret: data?.accessTokenSecret,
                conversation_guid: this.conversationGuid,
              });
              this.closeWindow();
            }
          }, this.boundOnError);
      } else {
        this.boundOnError();
      }
    }
    if (this.code && this.provider && this.conversationGuid) {
      this.oidcService
        .getNewAccessToken(this.code, this.provider, this.conversationGuid)
        .subscribe((data) => {
          if (data?.token && this.provider && this.conversationGuid) {
            this.sendToken({
              account_type: this.provider,
              token: data.token,
              conversation_guid: this.conversationGuid,
            });
          }
          this.closeWindow();
        }, this.boundOnError);
    } else {
      this.boundOnError();
    }
  }
}
