import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { NgForOf, NgIf, NgStyle } from '@angular/common';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import { TranslatePipe } from "@ngx-translate/core";

@Component({
  selector: 'app-prompts-list',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgForOf, NgIf, NgStyle, TranslatePipe],
  templateUrl: './prompts-list.component.html',
  styleUrl: './prompts-list.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PromptsListComponent implements OnChanges {
  @Input() prompts: string[] = [];
  @Input() prefix: string = '';
  @Input() disabled?: boolean = false;
  @Input() isMobile?: boolean = false;
  @Input() mainColor?: string = '#6271FF';
  @Output() handlePromptClick = new EventEmitter<string>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mainColor']?.currentValue) {
      const root = document.documentElement;
      root.style.setProperty('--main-color', this.mainColor || '#6271FF');
    }
  }

  onClick(prompt: string) {
    if (this.disabled) return;
    this.handlePromptClick.emit(prompt);
  }
}
